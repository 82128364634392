import React from "react";
import { Components, Form } from "@formio/react";
import components from "./Custom";
import axios from "axios";
import { Recognizer } from "./vosk/src/recognizer";
import "antd/dist/antd.min.css";
import $ from "jquery";
import UndoRedoComponent from "./Undo-Redo";
import {
  saveUndoRedoHistory,
  setSessionStorage,
  removeTableComponentFromData,
  cleanupSessionStorage,
} from "./Undo-Redo/helpers";
import { transformPremiumWidgetData } from "./Undo-Redo/premiumWidgetsHelpers";

Components.setComponents(components);

const sendData = function (schema,patientGuid, patientFormGuid, formGuid, type, appointmentGuid) {
  var data = schema.data
  console.log('Finish typing Console', formGuid);
      $('#save-timestamp-confirmation').html('Saving...');
  // let activeTab = $("div.tab-pane-full.active");
  // let activeForm = activeTab.find("form.autosave-form");
  let noteLocation = $("#note_location").val();
  let last_pressed_key = $("#last-pressed-key").val();
  
  if (data.drugAllergiesComp){
    data.drugAllergiesComp.widget_data = []
  }
  if (data.nonDrugAllergiesComp){
    data.nonDrugAllergiesComp.widget_data = []
  }
  if (data.medicalDiagnosisComp){
    data.medicalDiagnosisComp.widget_data = []
  }

  if (data.vitalSignsComp){
    data.vitalSignsComp.widget_data = []
  }

  if (data.immunizationComp){
    data.immunizationComp.widget_data = []
  }

  if (data.udiComp){
    data.udiComp.widget_data = []
  }

  if (data.proceduresComp){
    data.proceduresComp.widget_data = []
  }
  if (data.psychDiagnosisComp){
    data.psychDiagnosisComp.widget_data = []
  }

  if (data.medicationListComp){
    data.medicationListComp.widget_data = []
  }

  if (data.labsComp){
    data.labsComp.widget_data = []
  }

  axios
    .post(
      `/submission?id=${formGuid}&patient_guid=${patientGuid}&patient_form_guid=${patientFormGuid}&type=${type}&appointment_guid=${appointmentGuid}&note_location=${noteLocation}&last_pressed_key=${last_pressed_key}`,
      { data, note_location: noteLocation }
    )
    .then((res) => {
      if (res.data.updated_at){
        $('#toolbar-save-form').attr('disabled', true);
        $('#save-timestamp-confirmation').html("Last saved at "+ res.data.updated_at + " ");
        $("#copy-note-container .form-version-bubbles").removeClass("isDisabled");
        // $("#copy-note-container span#no-prior-note").hide();
        // $(".no-prior-note").show();
        // $("#patient_note_name").html(res.data.form_name);
        // $("#copy-note-container span.note_updated_at").html(res.data.updated_at);
      }
      else {
        swal("Error", "There was issue with saving this note. Please relogin and reopen this note.", 'error')
      }
    })
    .catch((error) => {
      swal("Error", "There was issue with saving this note. Please relogin and reopen this note.", 'error')
    });
};

const debouncedSubmission = _.debounce(sendData, 700);

class RenderFormBuilder extends React.Component {
  constructor(props) {
    super(props);

    var canEdit = $("#note-can-edit").val();
    var data = props.form_builder_data.value || props.last_saved_form_data || {};

    const form = JSON.parse(props.form_builder.content);
    const premiumComponentsInData = form.components.filter(
      (component) =>
        ["psychROSComp", "psychRosComp", "MentalStatusExamComp"].includes(
          component["type"]
        )
    );

    premiumComponentsInData.forEach((component)=>{
      data[component["key"]] = transformPremiumWidgetData(component, data && data[component["key"]] ? data[component["key"]] : null);
    })

    if (canEdit == "true") {
      setSessionStorage([data], 0, []);
    }

    this.formRef = React.createRef();
    this.state = {
      form: form,
      data: data,
    };
  }

  componentDidMount() {
    window.addEventListener('beforeunload', cleanupSessionStorage);
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.cleanup);
  }

  formChanged(changed, key, component_type) {
    if (component_type == "hiddenFieldCustomComp"){
      return true;
    }
    return (
      changed &&
      changed.flags &&
      changed.flags.fromSubmission == undefined &&
      [" ", "?", ".", "!", ";", ",", "script", "boolean", "Delete"].includes(
        key
      )
    );
  }



  submitFormData = (schema) => {
    validateForm("false");
    var canEdit = $("#note-can-edit").val();
    if (schema && schema.changed){
      $('#toolbar-save-form').attr('disabled', false);
    }
    if (canEdit == "true") {
      var key = $("#last-pressed-key").val();
      if (schema && schema.changed && schema.data && key != "" && !UndoRedoUnwantedComponents.includes(schema.changed.component.type)) {
        const data = Object.keys(schema.data).filter((val) => val.includes("tableCustomComp")).length > 0 ? removeTableComponentFromData(schema.data) : schema.data;
        try{
          saveUndoRedoHistory(data, schema.changed, key);
        } catch {

        }
      }
    }
  };

  updateChildState = (childId, value = null) => {

    const formioComponents = this.formRef;
    const componentToChange = formioComponents.getComponent(childId);

    if(value != null && value != undefined && componentToChange != undefined && componentToChange != null) {
      componentToChange.setValue(value);
    }
  };

  handleUndo = () => {
    var history = JSON.parse(sessionStorage.getItem("formHistory"));
    var currentIndex = Number(sessionStorage.getItem("currentIndex"), 10) || 0;
    var componentKeys = JSON.parse(sessionStorage.getItem("componentKeys"));

    $("#last-pressed-key").val("");
    if (currentIndex > 0) {
      const previousComponentKey =
        componentKeys[currentIndex] != componentKeys[currentIndex - 1]
          ? componentKeys[currentIndex]
          : "";
      currentIndex -= 1;
      this.updateChildState(
        componentKeys[currentIndex],
        history[currentIndex][componentKeys[currentIndex]]
      );

      if (previousComponentKey != "") {
        this.updateChildState(
          previousComponentKey,
          history[currentIndex][previousComponentKey]
        );
      }
    }
    sessionStorage.setItem("currentIndex", currentIndex);
  };

  handleRedo = () => {
    var history = JSON.parse(sessionStorage.getItem("formHistory"));
    var currentIndex = Number(sessionStorage.getItem("currentIndex"), 10) || 0;
    var componentKeys = JSON.parse(sessionStorage.getItem("componentKeys"));

    $("#last-pressed-key").val("");
    if (currentIndex < history.length - 1) {
      const previousComponentKey =
        componentKeys[currentIndex] != componentKeys[currentIndex + 1]
          ? componentKeys[currentIndex]
          : "";
      currentIndex += 1;
      this.updateChildState(
        componentKeys[currentIndex],
        history[currentIndex][componentKeys[currentIndex]]
      );
      if (previousComponentKey != "") {
        this.updateChildState(
          previousComponentKey,
          history[currentIndex][previousComponentKey]
        );
      }
    }
    sessionStorage.setItem("currentIndex", currentIndex);
  };

  componentDidUpdate = () => {
    const canEdit = $("#note-can-edit").val();

    if (canEdit == "true") {
      $(".ai-suggestion").removeClass("d-none");
    } else {
      $(".ai-suggestion").addClass("d-none");
    }
  }

  render() {
    const { form, data } = this.state;
    const patientGuid = this.props.patient_guid
    const patientFormGuid = this.props.patient_form_guid
    const appointmentGuid = this.props.appointment_guid
    const formGuid = this.props.form_builder.guid
    const type = this.props.type


    form["components"].push({
      "textFieldLabel": "Name",
      "type": "hiddenFieldCustomComp",
      "input": true,
      "key": "hiddenFieldCustomComp"
    },
    {
      "type": "button",
      "label": "Submit",
      "key": "submit",
      "disableOnInvalid": true,
      "input": true,
      "tableView": false,
      "customClass": "form-builder-submit-btn d-none"
      });
    var canEdit = $("#note-can-edit").val();

    return (
      <div id="form-builder-div">
          <UndoRedoComponent
            handleRedo={this.handleRedo}
            handleUndo={this.handleUndo}
            canEdit={canEdit}
            patientFormGuid={patientFormGuid}
            patientGuid={patientGuid}
            formGuid={formGuid}
            appointmentGuid={appointmentGuid}
          />
        <div className="form-div">
          <Form
            form={form}
            submission={{ data }}
            formReady = {(instance) => 
                          {
                            instance.on('change', (schema) => {
                              $('#toolbar-save-form').attr('disabled', false);
                                var canEdit = $("#note-can-edit").val();
                                if (canEdit == "true") {
                                  this.submitFormData(schema);
                                  if (schema && schema.changed && schema.data){
                                    debouncedSubmission(schema, patientGuid, patientFormGuid, formGuid, type, appointmentGuid);
                                  }
                                }
                            })
                            this.formRef = instance;
                          }
                        }
          />
        </div>
      </div>
    );
  }
}

export default RenderFormBuilder;